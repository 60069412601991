<template>
  <img
    alt="Logo"
    :src="$parent.logoData[$parent.logoFile]"
    height="250"
    style="margin-top: 40px"
  />
  <div class="center">
    <p>{{ $parent.language[this.gameName].headding }}</p>
    <p v-bind:class="{ isred: nameError }">
      {{ $parent.language[this.gameName].body }}
    </p>

    <div class="container" v-bind:class="{ green: clicked }">
      <div class="row" v-if="this.$parent.gameSettings.noBoxID != true">
        <input class="input" placeholder="Box ID " v-model="boxId" />
      </div>
      <div class="row">
        <input class="input" placeholder="Team Name" v-model="teamName" />
      </div>
      <div class="row">
        <button
          class="button"
          type="submit"
          :disabled="isDisable()"
          :style="{ 'background-color': $parent.buttonColor }"
          @click="SendTeamName()"
        >
          {{ $parent.language[this.gameName].go }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import { callApi } from "../plugins/callApi.js";
export default {
  name: "StartGameDemo",
  props: {
    msg: String,
    dbid: String,
    gameName: String,
  },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      nameError: false,
      clicked: false,
      boxId: "",
      teamName: "",
    };
  },
  mounted() {
    if (this.$parent.gameSettings.noBoxID == true) {
      this.boxId = this.getRandomNumber(0, 9000);
    }
  },

  methods: {
    getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    isDisable() {
      var dis = this.teamName.length == 0;
      console.log(dis);
      return dis;
    },
    async SendTeamName() {
      if (this.teamName == "") {
        this.nameError = true;
        this.clicked = false;
      } else {
        this.nameError = false;
        this.clicked = true;
        console.log("click");
        var setup = await callApi("newgame", this.license, {
          name: this.teamName,
          boxid: this.boxId,
          dbid: this.dbid,
          chosenLanguage: this.$parent.chosenLanguage,
        });
        //  await axios.post(process.env.VUE_APP_ip + '/newgame', { "name": this.name, "email": this.email, "mobile": this.mobile, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        this.$emit("updatestage", "");
      }
    },
  },
};
</script>


